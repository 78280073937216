// interface MessagePart {
//   sender: string;
//   content: string;
// }

const isMessageStart = (line: string): boolean =>
  line.startsWith("User:") ||
  line.startsWith("Assistant:") ||
  line.startsWith("Support:") ||
  line.startsWith("System:");

// const extractSenderAndContent = (line: string): MessagePart => {
//   const sender = line.split(":")[0];
//   const content = line.substring(line.indexOf(":") + 1).trim();
//   return { sender, content };
// };

// const findNextMessageIndex = (
//   lines: string[],
//   currentIndex: number
// ): number => {
//   const nextIndex = lines.findIndex(
//     (line, index) => index > currentIndex && isMessageStart(line.trim())
//   );
//   return nextIndex === -1 ? lines.length : nextIndex;
// };

// const getSubsequentContent = (
//   lines: string[],
//   startIndex: number,
//   endIndex: number
// ): string => {
//   const subsequentLines = lines
//     .slice(startIndex + 1, endIndex)
//     .map((line) => line.trim())
//     .filter((line) => line !== "");

//   return subsequentLines.length > 0 ? "\n" + subsequentLines.join("\n") : "";
// };

export const splitMessages = (message: string): string[] => {
  const parts: string[] = [];

  // First, normalize the message by ensuring proper spacing around message markers
  const normalizedMessage = message
    .replace(/(User:|Assistant:|Support:|System:)/g, "\n$1") // Add newline before each marker
    .replace(/<br>/g, "\n<br>"); // Add newline before each <br>

  const lines = normalizedMessage
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "");

  let currentMessage = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if (isMessageStart(line)) {
      if (currentMessage) {
        parts.push(currentMessage.trim());
      }
      currentMessage = line;
    } else if (line.startsWith("<br>")) {
      // Append timestamp to current message if it exists
      if (currentMessage) {
        currentMessage += line;
      }
    } else if (!line.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)) {
      // Only append if it's not a standalone timestamp
      if (currentMessage) {
        currentMessage += " " + line;
      }
    }
  }

  // Add the last message
  if (currentMessage) {
    parts.push(currentMessage.trim());
  }

  return parts.filter((part) => part.trim() !== "");
};

export const getLastMessage = (message: string): string => {
  const parts = splitMessages(message);

  // Find the last non-system message
  for (let i = parts.length - 1; i >= 0; i--) {
    const part = parts[i];
    if (!part.startsWith("System:")) {
      return part
        .replace(/<p>|<\/p>/g, "")
        .replace(/<div class='rupeni-option-btn-container'>.*?<\/div>/gs, "")
        .replace(/<br>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g, "")
        .replace(/<br>\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g, "")
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g, "")
        .replace(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g, "")
        .replace(/<br>/g, "")
        .replace(/\n\s*\n/g, "\n")
        .trim();
    }
  }
  return "";
};

export const extractNameAndEmail = (
  message: string
): { name: string; email: string } => {
  const userMessage = message
    .split("\n")
    .find((part) => part.startsWith("User:"));
  if (!userMessage) return { name: "Unknown", email: "Unknown" };

  const nameMatch = RegExp(/name is ([\w\s'.-]+) and/i).exec(userMessage);
  const emailMatch = RegExp(
    /email is (([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|([A-Z0-9._%+-]+@[A-Z0-9.-]+))/i
  ).exec(userMessage);

  const name = nameMatch ? nameMatch[1].trim() : "Unknown";
  const email = emailMatch ? emailMatch[1].trim() : "Unknown";

  return { name, email };
};

export const getInitials = (name: string): string => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  }
  return (
    nameParts[0].charAt(0).toUpperCase() +
    nameParts[nameParts.length - 1].charAt(0).toUpperCase()
  );
};

export const getSenderType = (
  messagePart: string
): "user" | "assistant" | "system" | "human_assistant" => {
  if (messagePart.startsWith("User:")) return "user";
  if (messagePart.startsWith("Support:")) return "human_assistant";
  if (messagePart.startsWith("System:")) return "system";
  return "assistant";
};

export const omitStart = (message: string): string => {
  return message
    .replace(/^(User|Assistant|Support|System):\s*/, "")
    .replace(/<p>|<\/p>/g, "")
    .trim();
};
