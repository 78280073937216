import { useEffect} from "react";
import io from "socket.io-client";

interface UseSessionTerminatorProps {
  onSessionTerminate: (sessionId: string) => void;
}

export function useSessionTerminator({
  onSessionTerminate,
}: UseSessionTerminatorProps) {
  useEffect(() => {
    const socket = io(
      "https://rupeni-terminator.globalbedbank.opalstacked.com/terminator",
      {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000,
      }
    );

    socket.on("connect", () => {
      console.log("Connected to terminator socket");
    });

    socket.on("connect_error", (error) => {
      console.error("Terminator socket connection error:", error);
    });

    socket.on("session_terminate", (data) => {
      console.log("Session terminated:", data);
      if (data && data.session_id) {
        onSessionTerminate(data.session_id);
      }
    });

    return () => {
      socket.off("session_terminate");
      socket.off("connect");
      socket.off("connect_error");
      socket.disconnect();
    };
  }, [onSessionTerminate]);
}
