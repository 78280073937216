import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { registerUser } from "../../api/authentication";
import { motion } from "framer-motion";

interface RegisterComponentProps {
  setTab: (tab: string) => void;
}

const RegisterComponent: React.FC<RegisterComponentProps> = ({ setTab }) => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyFinanceEmail, setCompanyFinanceEmail] = useState("");
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextStep = () => {
    const newErrors: { [key: string]: string } = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setStep(2);
    }
  };

  const handlePrevStep = () => {
    setStep(1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};

    if (!validateEmail(companyFinanceEmail)) {
      newErrors.companyFinanceEmail = "Invalid email format";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await registerUser({
          name,
          email,
          password,
          company_name: companyName,
          company_phone: companyPhone,
          company_address: companyAddress,
          company_finance_email: companyFinanceEmail,
          title,
        });
        setSuccess(response);
      } catch (error) {
        setErrors({
          apiError:
            error instanceof Error
              ? error.message
              : "An error occurred. Please try again later.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const formVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const childVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <div className="flex items-center justify-center lg:col-span-3 p-4">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10">
        <motion.h2
          className="text-3xl font-bold text-center mb-8 text-gray-800"
          variants={childVariants}
          initial="initial"
          animate="animate"
        >
          Create Your Account
        </motion.h2>
        {success ? (
          <motion.div
            variants={childVariants}
            initial="initial"
            animate="animate"
            className="flex flex-col items-center justify-center text-center"
          >
            <p className="text-green-500 mb-4" aria-live="polite">
              {success}
            </p>
            <button
              className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
              onClick={() => setTab("login")}
            >
              <span className="text-sm p-8">Login Now</span>
            </button>
          </motion.div>
        ) : (
          <motion.form
            onSubmit={handleSubmit}
            variants={formVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3 }}
            className="w-full"
          >
            <motion.div variants={childVariants}>
              {step === 1 ? (
                <>
                  <InputField
                    label="Name"
                    id="name"
                    value={name}
                    onChange={setName}
                    icon="user"
                  />
                  <InputField
                    label="Email"
                    id="email"
                    value={email}
                    onChange={setEmail}
                    icon="envelope"
                    error={errors.email}
                  />
                  <InputField
                    label="Password"
                    id="password"
                    value={password}
                    onChange={(value) => {
                      setPassword(value);
                      checkPasswordStrength(value);
                    }}
                    icon="lock"
                    type="password"
                  />
                  {password && (
                    <div className="mb-4">
                      <div className="flex gap-1 mt-1">
                        {[...Array(4)].map((_, i) => (
                          <div
                            key={i}
                            className={`h-1 flex-1 rounded-full ${
                              i < passwordStrength
                                ? passwordStrength === 1
                                  ? "bg-red-500"
                                  : passwordStrength === 2
                                  ? "bg-yellow-500"
                                  : passwordStrength === 3
                                  ? "bg-blue-500"
                                  : "bg-green-500"
                                : "bg-gray-200"
                            }`}
                          />
                        ))}
                      </div>
                      <p className="text-xs text-gray-500 mt-1">
                        {passwordStrength === 0 && "Add a password"}
                        {passwordStrength === 1 && "Weak password"}
                        {passwordStrength === 2 && "Fair password"}
                        {passwordStrength === 3 && "Good password"}
                        {passwordStrength === 4 && "Strong password"}
                      </p>
                    </div>
                  )}
                  <InputField
                    label="Confirm Password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    icon="lock"
                    type="password"
                    error={errors.confirmPassword}
                  />
                  <div className="flex justify-center mt-6">
                    <button
                      className="w-full bg-bg border border-bg hover:border-primary text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                      onClick={handleNextStep}
                      type="button"
                    >
                      <span>Next</span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <InputField
                    label="Company Name"
                    id="companyName"
                    value={companyName}
                    onChange={setCompanyName}
                    icon="building"
                  />
                  <InputField
                    label="Company Phone"
                    id="companyPhone"
                    value={companyPhone}
                    onChange={setCompanyPhone}
                    icon="phone"
                  />
                  <InputField
                    label="Company Address"
                    id="companyAddress"
                    value={companyAddress}
                    onChange={setCompanyAddress}
                    icon="map-marker-alt"
                  />
                  <InputField
                    label="Company Finance Email"
                    id="companyFinanceEmail"
                    value={companyFinanceEmail}
                    onChange={setCompanyFinanceEmail}
                    icon="envelope"
                    error={errors.companyFinanceEmail}
                  />
                  <InputField
                    label="Your Title"
                    id="title"
                    value={title}
                    onChange={setTitle}
                    icon="briefcase"
                  />
                  <div className="flex justify-between mt-6">
                    <button
                      type="button"
                      className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                      onClick={handlePrevStep}
                    >
                      <span className="text-sm p-8">Previous</span>
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                    >
                      <span className="text-sm p-8">
                        {loading ? (
                          <>
                            <Spinner aria-label="Spinner" size="sm" />
                            <span className="pl-3">Loading...</span>
                          </>
                        ) : (
                          "Register"
                        )}
                      </span>
                    </button>
                  </div>
                </>
              )}
              {errors.apiError && (
                <p className="text-red-500 text-xs mt-1" aria-live="polite">
                  {errors.apiError}
                </p>
              )}
            </motion.div>
          </motion.form>
        )}
        <motion.div
          variants={childVariants}
          initial="initial"
          animate="animate"
          className="text-center mt-8"
        >
          <p className="text-sm text-gray-500">
            Already have an account?{" "}
            <button
              onClick={() => setTab("login")}
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
            >
              Login
            </button>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

// Helper component for input fields
const InputField: React.FC<{
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  icon: string;
  type?: string;
  error?: string;
}> = ({ label, id, value, onChange, icon, type = "text", error }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <i className={`fas fa-${icon} text-gray-400`}></i>
      </div>
      <input
        type={type}
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required
      />
    </div>
    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
  </div>
);

export default RegisterComponent;
