import { useState, useCallback, useRef, useEffect } from "react";
import { ChatLog, ChatLogsResponse } from "../../../utils/types/chat";
import { getChatLogs, releaseChat, takeOverChat } from "../../../api/chat";
import { useAuth } from "../../../utils/helpers/authWrapper";
import { useNavigate } from "react-router-dom";
import { splitMessages } from "../utils/messageHelpers";
import { HumanAssistanceRequest } from "../types";
import { useWebSocketContext } from "../../../utils/context";
import { useAudio } from "./useAudio";
import { usePermissions } from "../../../utils/hooks/usePermissions";
import useWebSocket from "react-use-websocket";
import { useSessionTerminator } from "./useSessionTerminator";

interface FetchParams {
  search?: string;
  domain_id?: number;
  selectedLogId?: string;
}

interface WebSocketMessage {
  type:
    | "user_message"
    | "assistant_message"
    | "human_assistance_request"
    | "new_call_request"
    | "chat_assigned";
  message?: string;
  sessionID?: string;
  domain?: string;
  data?: {
    session_id?: string;
    domain?: string;
    name?: string;
  };
}

interface QueuedMessage {
  id: string;
  sessionId: string;
  message: string;
  timestamp: number;
  type: "incoming" | "outgoing";
  processed: boolean;
}

interface UseChatProps {
  onChatEnded?: (sessionId: string) => void;
}

export function useChat({ onChatEnded }: UseChatProps = {}) {
  const [chatLogs, setChatLogs] = useState<ChatLogsResponse>([]);
  const [selectedLog, setSelectedLog] = useState<ChatLog | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [intervenedChats, setIntervenedChats] = useState<Set<string>>(
    new Set()
  );
  const [highlightedMessageIds, setHighlightedMessageIds] = useState<
    Set<string>
  >(new Set());
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [lastHumanAssistanceRequest, setLastHumanAssistanceRequest] =
    useState<HumanAssistanceRequest | null>(null);
  const [humanAssistanceRequests, setHumanAssistanceRequests] = useState<
    Map<string, HumanAssistanceRequest>
  >(new Map());

  const currentOffsetRef = useRef(0);
  const isFetchingRef = useRef(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { clearNotification, clearAllNotificationsForSession } =
    useWebSocketContext();
  const { stopSound } = useAudio();
  const { can } = usePermissions();
  const canTakeOver = can("chat_takeover");

  const POLLING_INTERVAL = 5000;

  // Add new ref for pending support messages
  const pendingSupportMessages = useRef<
    Map<string, { message: string; timestamp: number }>
  >(new Map());

  // Add new ref for pending incoming messages
  const pendingIncomingMessages = useRef<
    Map<string, { message: string; timestamp: number; type: string }>
  >(new Map());

  // Add message queue state
  const messageQueue = useRef<QueuedMessage[]>([]);
  const processingQueue = useRef<boolean>(false);

  // Add new state for optimistic updates
  const [pendingUpdates, setPendingUpdates] = useState<
    Map<string, QueuedMessage>
  >(new Map());

  // Add near other refs at the top
  const endedChatMessages = useRef<Map<string, string>>(new Map());

  const user = auth.user;
  // Add helper function for API URL
  const getApiUrl = () => {
    const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;
    return env === "production"
      ? import.meta.env.VITE_REACT_APP_PRODUCTION_API_URL
      : import.meta.env.VITE_REACT_APP_DEVELOPMENT_API_URL;
  };
  // Add WebSocket hook
  const { sendMessage: sendSupportMessage } = useWebSocket(
    `${getApiUrl().replace("https:", "wss:")}/chat?type=support&support_id=${
      user?.id.toString() ?? ""
    }&support_name=${user?.name ?? ""}`,
    {
      shouldReconnect: () => true,
    }
  );

  const updateChatMessages = useCallback((log: ChatLog) => {
    const messages = splitMessages(log.message);
    setChatMessages(messages);
  }, []);

  const fetchChatLogs = useCallback(
    async (
      resetOffset = false,
      params: FetchParams = {},
      isPolling = false
    ) => {
      if (isFetchingRef.current) return;

      try {
        isFetchingRef.current = true;

        if (resetOffset && (params.search || params.domain_id)) {
          setIsLoading(true);
          currentOffsetRef.current = 0;
        } else {
          setIsLoadingMore(true);
        }

        const data = await getChatLogs({
          limit: 10,
          offset: isPolling ? 0 : currentOffsetRef.current,
          ...params,
        });

        if (data) {
          setChatLogs((prevItems) => {
            const existingLogs =
              resetOffset && (params.search || params.domain_id)
                ? []
                : prevItems;
            const logsMap = new Map(
              existingLogs.map((log) => [log.session_id, log])
            );

            if (isPolling) {
              data.forEach((newLog) => {
                // Handle pending support messages
                const pendingSupport = Array.from(
                  pendingSupportMessages.current.entries()
                ).filter(([key]) => key.startsWith(newLog.session_id));

                // Handle pending incoming messages
                const pendingIncoming = Array.from(
                  pendingIncomingMessages.current.entries()
                ).filter(([key]) => key.startsWith(newLog.session_id));

                let updatedMessage = newLog.message;

                // Add pending support messages if not in server response
                pendingSupport.forEach(([key, { message }]) => {
                  if (!newLog.message.includes(message)) {
                    updatedMessage += "\n\nSupport: " + message;
                  } else {
                    pendingSupportMessages.current.delete(key);
                  }
                });

                // Add pending incoming messages if not in server response
                pendingIncoming.forEach(([key, { message, type }]) => {
                  const prefix =
                    type === "user_message" ? "User: " : "Assistant: ";
                  const fullMessage = `${prefix}${message}`;
                  if (!newLog.message.includes(fullMessage)) {
                    updatedMessage += "\n\n" + fullMessage;
                  } else {
                    pendingIncomingMessages.current.delete(key);
                  }
                });

                // Add this after handling pending messages
                const endedMessage = endedChatMessages.current.get(
                  newLog.session_id
                );
                if (endedMessage && !newLog.message.includes(endedMessage)) {
                  updatedMessage += endedMessage;
                }

                newLog.message = updatedMessage;
                logsMap.set(newLog.session_id, newLog);

                // Update selected log if needed
                if (
                  selectedLog?.session_id === newLog.session_id &&
                  newLog.message !== selectedLog.message
                ) {
                  setSelectedLog(newLog);
                  updateChatMessages(newLog);
                }
              });
            } else {
              data.forEach((newLog) => {
                const existing = logsMap.get(newLog.session_id);
                if (existing) {
                  if (existing.message !== newLog.message) {
                    logsMap.set(newLog.session_id, newLog);

                    // If this is the selected log, update its messages
                    if (selectedLog?.session_id === newLog.session_id) {
                      setSelectedLog(newLog);
                      updateChatMessages(newLog);
                    }
                  }
                } else {
                  logsMap.set(newLog.session_id, newLog);
                }
              });
            }

            const newLogs = Array.from(logsMap.values()).sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            );

            if (
              !isPolling &&
              (!resetOffset || !(params.search || params.domain_id))
            ) {
              currentOffsetRef.current += data.length;
            }

            return newLogs;
          });

          if (!isPolling) {
            setHasMore(data.length === 10);
          }
        } else {
          if (!isPolling) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Error fetching chat logs:", error);
        if (!isPolling) {
          setHasMore(false);
        }
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
        isFetchingRef.current = false;
      }
    },
    [selectedLog, updateChatMessages]
  );

  useEffect(() => {
    const pollInterval = setInterval(() => {
      fetchChatLogs(false, {}, true);
    }, POLLING_INTERVAL);

    return () => clearInterval(pollInterval);
  }, [fetchChatLogs]);

  useEffect(() => {
    fetchChatLogs(false);
  }, [fetchChatLogs]);

  const fetchUpdatedLog = useCallback(async (sessionId: string) => {
    try {
      const updatedLogs = await getChatLogs({ limit: 10, offset: 0 });
      if (updatedLogs && updatedLogs.length > 0) {
        const relevantLog = updatedLogs.find(
          (log) => log.session_id === sessionId
        );

        if (relevantLog) {
          setChatLogs((prevLogs) => {
            const updatedLogIndex = prevLogs.findIndex(
              (log) => log.session_id === sessionId
            );
            if (updatedLogIndex !== -1) {
              const newLogs = [...prevLogs];
              newLogs[updatedLogIndex] = relevantLog;
              return newLogs;
            }
            return [relevantLog, ...prevLogs];
          });

          setSelectedLog((prevSelectedLog) => {
            if (prevSelectedLog && prevSelectedLog.session_id === sessionId) {
              return relevantLog;
            }
            return prevSelectedLog;
          });
        }
      }
    } catch (error) {
      console.error("Error fetching updated log:", error);
    }
  }, []);

  const handleReleaseChat = async (session_id: string) => {
    if (!canTakeOver) return;

    const chatLog = chatLogs.find((log) => log.session_id === session_id);
    if (!chatLog) {
      console.error("Chat log not found for session:", session_id);
      return;
    }

    const { user } = auth;
    console.log("Attempting to release chat:", {
      session_id,
      userId: user?.id,
    });

    try {
      const success = await releaseChat(
        chatLog.domain,
        session_id,
        user?.id.toString() ?? ""
      );
      console.log("Release chat response:", success);

      if (success) {
        setIntervenedChats((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });

        // Clear ALL notifications for this session
        clearAllNotificationsForSession(session_id);
        stopSound();
        setLastHumanAssistanceRequest(null);

        // Also clear from human assistance requests
        setHumanAssistanceRequests((prev) => {
          const newMap = new Map(prev);
          newMap.delete(session_id);
          return newMap;
        });
      }
    } catch (error) {
      console.error("Error releasing chat:", error);
    }
  };

  const handleIntervene = async (session_id: string) => {
    if (!canTakeOver) return;

    const chatLog = chatLogs.find((log) => log.session_id === session_id);
    if (!chatLog) {
      console.error("Chat log not found for session:", session_id);
      return;
    }

    // Stop sound immediately before API call
    stopSound();

    const { user } = auth;
    console.log("Attempting to intervene:", { session_id, userId: user?.id });

    try {
      const success = await takeOverChat(
        chatLog.domain,
        session_id,
        user?.id.toString() ?? ""
      );
      console.log("Intervene response:", success);

      if (success) {
        // Stop sound first before making any state changes
        stopSound();

        setIntervenedChats((prev) => new Set(prev).add(session_id));

        // Remove from highlighted messages
        setHighlightedMessageIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });

        // Clear notification and reset human assistance request
        clearNotification(session_id);
        setLastHumanAssistanceRequest(null);

        // Remove from human assistance requests
        setHumanAssistanceRequests((prev) => {
          const newMap = new Map(prev);
          newMap.delete(session_id);
          return newMap;
        });

        if (selectedLog) {
          const updatedLog = { ...selectedLog };
          setSelectedLog(updatedLog);
          updateChatMessages(updatedLog);
        }

        navigate("/chats", { replace: true, state: {} });
      }
    } catch (error) {
      console.error("Error intervening:", error);
    }
  };

  // Process message queue
  const processMessageQueue = useCallback(async () => {
    if (processingQueue.current || messageQueue.current.length === 0) return;

    processingQueue.current = true;

    while (messageQueue.current.length > 0) {
      const message = messageQueue.current[0];

      if (!message.processed) {
        setChatLogs((prevLogs) =>
          prevLogs.map((log) => {
            if (log.session_id === message.sessionId) {
              const messagePrefix =
                message.type === "incoming" ? "User: " : "Support: ";
              const updatedMessage =
                log.message + "\n\n" + messagePrefix + message.message;
              return { ...log, message: updatedMessage };
            }
            return log;
          })
        );

        // Update selected log if needed
        if (selectedLog?.session_id === message.sessionId) {
          const messagePrefix =
            message.type === "incoming" ? "User: " : "Support: ";
          const updatedMessage =
            selectedLog.message + "\n\n" + messagePrefix + message.message;
          setSelectedLog((prev) =>
            prev ? { ...prev, message: updatedMessage } : null
          );
          updateChatMessages({ ...selectedLog, message: updatedMessage });
        }

        message.processed = true;
      }

      messageQueue.current.shift();
    }

    processingQueue.current = false;
  }, [selectedLog, updateChatMessages]);

  // Modified handleSendMessage
  const handleSendMessage = useCallback(
    (messageText: string) => {
      if (!selectedLog?.session_id) return;

      const messageId = `${selectedLog.session_id}-${Date.now()}`;
      const queuedMessage: QueuedMessage = {
        id: messageId,
        sessionId: selectedLog.session_id,
        message: messageText,
        timestamp: Date.now(),
        type: "outgoing",
        processed: false,
      };

      // Optimistic update
      setPendingUpdates((prev) => {
        const newMap = new Map(prev);
        newMap.set(messageId, queuedMessage);
        return newMap;
      });

      // Add to queue
      messageQueue.current.push(queuedMessage);
      processMessageQueue();

      const messageData = {
        type: "support_message",
        domain: selectedLog.domain,
        clientID: selectedLog.session_id,
        message: messageText,
      };
      sendSupportMessage(JSON.stringify(messageData));
    },
    [selectedLog, processMessageQueue, sendSupportMessage]
  );

  // Modified handleWebSocketMessage
  const handleWebSocketMessage = useCallback(
    (data: WebSocketMessage) => {
      if (!data || !canTakeOver) return;

      try {
        const sessionId = data.sessionID || data.data?.session_id;
        if (!sessionId) return;

        if (data.type === "user_message" || data.type === "assistant_message") {
          const messageId = `${sessionId}-${Date.now()}`;
          const queuedMessage: QueuedMessage = {
            id: messageId,
            sessionId,
            message: data.message || "",
            timestamp: Date.now(),
            type: "incoming",
            processed: false,
          };

          messageQueue.current.push(queuedMessage);
          processMessageQueue();
        }

        // Handle chat assignment
        if (data.type === "chat_assigned") {
          // Always stop sound for this session regardless of other conditions
          stopSound();

          setHighlightedMessageIds((prev) => {
            const newSet = new Set(prev);
            newSet.delete(sessionId);
            return newSet;
          });

          setHumanAssistanceRequests((prev) => {
            const newMap = new Map(prev);
            newMap.delete(sessionId);
            return newMap;
          });

          setLastHumanAssistanceRequest((current) =>
            current?.sessionID === sessionId ? null : current
          );

          // Clear notification for this session
          clearNotification(sessionId);

          return;
        }

        // For human assistance request
        if (data.type === "human_assistance_request") {
          if (canTakeOver && data.domain) {
            // First stop any existing sound to reset the isPlayingRef
            stopSound();

            setHighlightedMessageIds((prev) => new Set(prev).add(sessionId));

            const newRequest: HumanAssistanceRequest = {
              domain: data.domain,
              messageID: Date.now(),
              reason: data.message || "No reason provided",
              sessionID: sessionId,
              type: "human_assistance_request",
            };

            setHumanAssistanceRequests((prev) => {
              const newMap = new Map(prev);
              newMap.set(sessionId, newRequest);
              return newMap;
            });

            setLastHumanAssistanceRequest(newRequest);
          }
          return;
        }
      } catch (error) {
        console.error("Error handling WebSocket message:", error);
      }
    },
    [canTakeOver, processMessageQueue]
  );

  // Add effect to process queue periodically
  useEffect(() => {
    const interval = setInterval(() => {
      processMessageQueue();
    }, 100);

    return () => clearInterval(interval);
  }, [processMessageQueue]);

  const pollForUpdates = useCallback(async () => {
    try {
      const recentData = await getChatLogs({ limit: 10, offset: 0 });
      if (recentData) {
        setChatLogs((prevLogs) => {
          const updatedLogs = prevLogs.map((log) => {
            const updatedLog = recentData.find(
              (recent) => recent.session_id === log.session_id
            );
            return updatedLog || log;
          });

          // Add any new logs
          const newLogs = recentData.filter(
            (log) =>
              !prevLogs.some(
                (existing) => existing.session_id === log.session_id
              )
          );

          return [...newLogs, ...updatedLogs]
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .slice(0, 100);
        });

        // Update selected log if it exists in the new data
        if (selectedLog) {
          const updatedSelectedLog = recentData.find(
            (log) => log.session_id === selectedLog.session_id
          );
          if (
            updatedSelectedLog &&
            updatedSelectedLog.message !== selectedLog.message
          ) {
            setSelectedLog(updatedSelectedLog);
            updateChatMessages(updatedSelectedLog);
          }
        }
      }
    } catch (error) {
      console.error("Error polling for updates:", error);
    }
  }, [selectedLog, updateChatMessages]);

  // Clear highlights and assistance requests for viewers
  useEffect(() => {
    if (!canTakeOver) {
      setHighlightedMessageIds(new Set());
      setHumanAssistanceRequests(new Map());
      setLastHumanAssistanceRequest(null);
    }
  }, [canTakeOver]);

  // Add cleanup for old pending messages
  useEffect(() => {
    const cleanup = setInterval(() => {
      const now = Date.now();
      Array.from(pendingSupportMessages.current.entries()).forEach(
        ([key, { timestamp }]) => {
          if (now - timestamp > 30000) {
            // Remove after 30 seconds
            pendingSupportMessages.current.delete(key);
          }
        }
      );
    }, 10000);

    return () => clearInterval(cleanup);
  }, []);

  // Add cleanup for pending incoming messages
  useEffect(() => {
    const cleanup = setInterval(() => {
      const now = Date.now();
      Array.from(pendingIncomingMessages.current.entries()).forEach(
        ([key, { timestamp }]) => {
          if (now - timestamp > 30000) {
            pendingIncomingMessages.current.delete(key);
          }
        }
      );
    }, 10000);

    return () => clearInterval(cleanup);
  }, []);

  const handleSessionTerminate = useCallback(
    (sessionId: string) => {
      const systemMessage = "\n\nSystem: User has ended the chat\n\n";
      endedChatMessages.current.set(sessionId, systemMessage);

      // Clear all states related to active chat
      setIntervenedChats((prev) => {
        const newSet = new Set(prev);
        newSet.delete(sessionId);
        return newSet;
      });

      // Clear human assistance request states
      setHighlightedMessageIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(sessionId);
        return newSet;
      });

      setLastHumanAssistanceRequest((current) =>
        current?.sessionID === sessionId ? null : current
      );

      setHumanAssistanceRequests((prev) => {
        const newMap = new Map(prev);
        newMap.delete(sessionId);
        return newMap;
      });

      // Stop notification sound if it's playing for this session
      stopSound();

      // Emit chat ended event to parent
      onChatEnded?.(sessionId);

      // Update chat logs with ended status
      setChatLogs((prevLogs) =>
        prevLogs.map((log) => {
          if (log.session_id === sessionId) {
            return {
              ...log,
              message: log.message + systemMessage,
            };
          }
          return log;
        })
      );

      // Update selected log if needed
      if (selectedLog?.session_id === sessionId) {
        const updatedLog = {
          ...selectedLog,
          message: selectedLog.message + systemMessage,
        };
        setSelectedLog(updatedLog);
        updateChatMessages(updatedLog);
      }
    },
    [
      selectedLog,
      onChatEnded,
      setChatLogs,
      setSelectedLog,
      updateChatMessages,
      setHighlightedMessageIds,
      setLastHumanAssistanceRequest,
      setHumanAssistanceRequests,
      stopSound,
    ]
  );

  // Use the terminator socket instead of polling
  useSessionTerminator({
    onSessionTerminate: handleSessionTerminate,
  });

  return {
    chatLogs,
    selectedLog,
    isLoading,
    isLoadingMore,
    hasMore,
    intervenedChats,
    highlightedMessageIds: canTakeOver ? highlightedMessageIds : new Set(),
    chatMessages,
    lastHumanAssistanceRequest: canTakeOver ? lastHumanAssistanceRequest : null,
    setSelectedLog,
    setChatMessages,
    setChatLogs,
    setHighlightedMessageIds,
    setLastHumanAssistanceRequest,
    fetchChatLogs,
    fetchUpdatedLog,
    handleIntervene,
    handleReleaseChat,
    updateChatMessages,
    handleSendMessage,
    handleWebSocketMessage,
    pollForUpdates,
    setIntervenedChats,
    humanAssistanceRequests: canTakeOver ? humanAssistanceRequests : new Map(),
    pendingUpdates,
  };
}
