import React from "react";
import { UserAvatar } from "./UserAvatar";
import DOMPurify from "dompurify";
import { format, parseISO } from "date-fns";

interface ChatMessageProps {
  message: string;
  sender: "user" | "assistant" | "system" | "human_assistant";
  initials: string;
  agentName?: string;
  timestamp?: string;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  sender,
  initials,
  agentName,
  timestamp,
}) => {
  // Enhanced message cleaning to handle both timestamp formats
  const { cleanedMessage, extractedTimestamp } = React.useMemo(() => {
    // First remove the rupeni button container
    let cleaned = message.replace(
      /<div class='rupeni-option-btn-container'>.*?<\/div>/gs,
      ""
    );

    console.log("sender", sender);
    console.log("message", message);

    // Extract timestamp from the message - handle both formats
    const isoTimestampMatch = cleaned.match(
      /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)/
    );
    const regularTimestampMatch = cleaned.match(
      /(\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2})/
    );
    const extractedTime = isoTimestampMatch
      ? isoTimestampMatch[1]
      : regularTimestampMatch
      ? regularTimestampMatch[1].replace(" ", "T") + "Z"
      : timestamp;

    // Remove timestamps and HTML tags
    cleaned = cleaned
      // Handle ISO format timestamps
      .replace(/<p>(.*?)<br>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z<\/p>/g, "$1")
      .replace(/<br>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g, "")
      // Handle regular format timestamps
      .replace(/<p>(.*?)<br>\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}<\/p>/g, "$1")
      .replace(/<br>\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g, "")
      // Remove standalone timestamps of both formats
      .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g, "")
      .replace(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g, "")
      // Remove HTML tags and clean up
      .replace(/<p>|<\/p>|<br>/g, "")
      .replace(/\n\s*\n/g, "\n")
      .trim();

    return {
      cleanedMessage: cleaned,
      extractedTimestamp: extractedTime,
    };
  }, [message, timestamp]);

  // Create a sanitized version of the HTML
  const createSanitizedHTML = (content: string) => {
    const sanitizedHTML = DOMPurify.sanitize(content, {
      ALLOWED_TAGS: ["p", "br", "ul", "li", "a", "div", "button"],
      ALLOWED_ATTR: ["href", "target", "class"],
    });
    return { __html: sanitizedHTML };
  };

  const getMessageStyles = (isUser: boolean, isHumanAssistant: boolean) => {
    if (isUser) {
      return "bg-blue-500 text-white";
    }
    if (isHumanAssistant) {
      return "bg-green-500 text-white";
    }
    return "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100";
  };

  // Format timestamp - handle both formats
  const messageTime = extractedTimestamp
    ? extractedTimestamp.includes("T")
      ? format(parseISO(extractedTimestamp), "h:mm a")
      : format(new Date(extractedTimestamp.replace(" ", "T") + "Z"), "h:mm a")
    : "";

  if (sender === "system") {
    return (
      <div className="flex justify-center my-2">
        <span className="text-xs text-gray-500 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 px-3 py-1 rounded-full">
          {cleanedMessage}
        </span>
      </div>
    );
  }

  const isUser = sender === "user";
  const isHumanAssistant = sender === "human_assistant";

  return (
    <div
      className={`flex ${
        isUser ? "justify-start" : "justify-end"
      } mb-4 items-start`}
    >
      {isUser && (
        <div className="flex-shrink-0 mr-2">
          <UserAvatar name={initials} size="sm" />
        </div>
      )}
      <div className={`flex flex-col ${isUser ? "" : "items-end"}`}>
        <div
          className={`px-4 py-2 rounded-lg ${getMessageStyles(
            isUser,
            isHumanAssistant
          )} break-words [&_ul]:list-disc [&_ul]:pl-4 [&_a]:underline [&_a]:text-blue-200 hover:[&_a]:text-blue-100`}
          style={{ maxWidth: "fit-content" }}
        >
          <div
            className="whitespace-pre-wrap break-words"
            dangerouslySetInnerHTML={createSanitizedHTML(cleanedMessage)}
          />
        </div>
        {messageTime && (
          <span className="text-xs text-gray-500 mt-1">{messageTime}</span>
        )}
      </div>
      {!isUser && (
        <div className="flex-shrink-0 ml-2">
          <UserAvatar
            name={isHumanAssistant ? agentName || "Support" : "AI"}
            size="sm"
          />
        </div>
      )}
    </div>
  );
};
