import React, { useState, useEffect } from "react";
import { Modal, Button, Radio, Spinner } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getChatHistoryByDateRange } from "../../api/analytics";
import { useAuth } from "../../utils/helpers/authWrapper";
import { CSVLink } from "react-csv";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FaDownload } from "react-icons/fa";
import moment from "moment";

interface ExportProps {
  filename: string;
  children?: React.ReactNode;
}

interface ChatLogExport {
  name: string;
  email: string;
  country: string;
  created_at: string;
  agents: string;
}

type ExportFormat = "csv" | "excel" | "pdf";

const Export: React.FC<ExportProps> = ({ filename, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportOption, setExportOption] = useState<"all" | "custom">("all");
  const [exportFormat, setExportFormat] = useState<ExportFormat>("csv");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState<ChatLogExport[]>([]);
  const [shouldDownloadCSV, setShouldDownloadCSV] = useState(false);
  const { user } = useAuth();

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Country", key: "country" },
    { label: "Date", key: "created_at" },
    { label: "Agents", key: "agents" },
  ];

  const formatDate = (dateString: string): string => {
    return moment(dateString).format("MMM DD, YYYY, hh:mm A");
  };

  const extractAgents = (message: string): string => {
    const lines = message.split("\n");
    const agentSet = new Set<string>();

    lines.forEach((line) => {
      const agentMatch = RegExp(/Support agent (.*?) has/).exec(line);
      if (agentMatch && agentMatch[1] !== "AI") {
        agentSet.add(agentMatch[1]);
      }
    });

    return Array.from(agentSet).join(", ") || " ";
  };

  const handleExport = async (format: ExportFormat) => {
    setIsLoading(true);
    try {
      const params = {
        user_id: user?.id ?? 0,
        ...(exportOption === "custom" && startDate && endDate
          ? {
              start_date: new Date(
                startDate.setHours(0, 0, 0, 0)
              ).toISOString(),
              end_date: new Date(
                endDate.setHours(23, 59, 59, 999)
              ).toISOString(),
            }
          : {}),
      };

      const response = await getChatHistoryByDateRange(params);
      const formattedData = response.map((log) => ({
        name: extractNameAndEmail(log.message).name,
        email: extractNameAndEmail(log.message).email,
        country: log.country,
        created_at:
          format === "pdf" ? formatDate(log.created_at) : log.created_at,
        agents: extractAgents(log.message),
      }));

      setExportData(formattedData);

      switch (format) {
        case "csv":
          setShouldDownloadCSV(true);
          break;
        case "excel":
          exportToExcel(formattedData);
          break;
        case "pdf":
          exportToPDF(formattedData);
          break;
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToExcel = (data: ChatLogExport[]) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Chat Logs');
    
    // Add headers
    worksheet.columns = [
      { header: 'Name', key: 'name', width: 20 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'Country', key: 'country', width: 15 },
      { header: 'Date', key: 'created_at', width: 25 },
      { header: 'Agents', key: 'agents', width: 25 }
    ];
    
    // Add rows
    data.forEach(row => {
      worksheet.addRow(row);
    });
    
    // Style headers
    worksheet.getRow(1).font = { bold: true };
    
    // Generate and save file
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${filename}.xlsx`);
    });
  };

  const exportToPDF = (data: ChatLogExport[]) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Chat Logs Report", 14, 15);

    doc.setFontSize(10);
    doc.text(
      `Generated on: ${moment().format("MMM DD, YYYY, hh:mm A")}`,
      14,
      25
    );

    // @ts-expect-error - autoTable is added by the import
    doc.autoTable({
      head: [["Name", "Email", "Country", "Date", "Agents"]],
      body: data.map((row) => [
        row.name || "N/A",
        row.email || "N/A",
        row.country || "N/A",
        row.created_at,
        row.agents,
      ]),
      startY: 35,
      styles: {
        fontSize: 8,
        cellPadding: 2,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [66, 135, 245],
        textColor: 255,
        fontSize: 9,
        fontStyle: "bold",
      },
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 45 },
        2: { cellWidth: 25 },
        3: { cellWidth: 35 },
        4: { cellWidth: 35 },
      },
      theme: "grid",
      margin: { top: 35 },
    });

    doc.save(`${filename}.pdf`);
  };

  useEffect(() => {
    if (shouldDownloadCSV && exportData.length > 0) {
      const downloadLink = document.querySelector(".csv-download-link");
      if (downloadLink instanceof HTMLElement) {
        downloadLink.click();
      }
      setShouldDownloadCSV(false);
    }
  }, [shouldDownloadCSV, exportData]);

  const extractNameAndEmail = (
    message: string
  ): { name: string; email: string } => {
    const userMessage = message
      .split("\n")
      .find((part) => part.startsWith("User:"));
    if (!userMessage) return { name: "Unknown", email: "Unknown" };

    const nameMatch = RegExp(/name is ([\w\s'.-]+) and/i).exec(userMessage);
    const emailMatch = RegExp(
      /email is (([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+))/i
    ).exec(userMessage);

    return {
      name: nameMatch ? nameMatch[1].trim() : "Unknown",
      email: emailMatch ? emailMatch[1].trim() : "Unknown",
    };
  };

  return (
    <>
      {children ? (
        <div onClick={() => setIsModalOpen(true)}>{children}</div>
      ) : (
        <Button onClick={() => setIsModalOpen(true)}>
          <FaDownload className="mr-2" />
          Export
        </Button>
      )}

      {exportData.length > 0 && (
        <CSVLink
          data={exportData}
          headers={headers}
          filename={`${filename}.csv`}
          className="csv-download-link"
          onClick={() => {
            setExportData([]);
            setShouldDownloadCSV(false);
          }}
          style={{ display: "none" }}
        >
          Download
        </CSVLink>
      )}

      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="md"
        popup
      >
        <Modal.Header>
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Export Chat Logs
          </h3>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <div className="flex items-center">
                  <Radio
                    id="all"
                    name="exportOption"
                    value="all"
                    checked={exportOption === "all"}
                    onChange={() => setExportOption("all")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="all"
                    className="text-gray-700 dark:text-gray-300"
                  >
                    All
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id="custom"
                    name="exportOption"
                    value="custom"
                    checked={exportOption === "custom"}
                    onChange={() => setExportOption("custom")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="custom"
                    className="text-gray-700 dark:text-gray-300"
                  >
                    Custom Date Range
                  </label>
                </div>

                <div className="flex gap-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date | null) => setStartDate(date)}
                    disabled={exportOption === "all"}
                    placeholderText="Start Date"
                    className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                      exportOption === "all"
                        ? "bg-gray-200 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date | null) => setEndDate(date)}
                    disabled={exportOption === "all"}
                    placeholderText="End Date"
                    className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                      exportOption === "all"
                        ? "bg-gray-200 cursor-not-allowed"
                        : ""
                    }`}
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Save as
                  </label>
                  <select
                    value={exportFormat}
                    onChange={(e) =>
                      setExportFormat(e.target.value as ExportFormat)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  >
                    <option value="csv">CSV File (.csv)</option>
                    <option value="excel">Excel Spreadsheet (.xlsx)</option>
                    <option value="pdf">PDF Document (.pdf)</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-center gap-4 mt-6">
                <Button
                  color="light"
                  onClick={() => handleExport(exportFormat)}
                  disabled={
                    exportOption === "custom" && (!startDate || !endDate)
                  }
                >
                  Export
                </Button>
                <Button color="gray" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Export;
